<template>
    <vx-card class="graphStatGender">
        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="7" vs-lg="7">
                <table>
                    <tr :style="{'color':themeColors[0] }">
                        <td>Homme</td>
                        <td>{{ homme }}</td>
                    </tr>
                    <tr :style="{'color':themeColors[1] }">
                        <td>Femme</td>
                        <td>{{ femme }}</td>
                    </tr>
                </table>
            </vs-col>
            <vs-col vs-xs="12" vs-sm="5" vs-lg="5">
                <vue-apex-charts ref="donut" type="donut" height="200" :options="donutChart.chartOptions" :series="donutChart.series"></vue-apex-charts>
            </vs-col>
        </vs-row>
    </vx-card>
</template>

<style lang="scss">
.graphStatGender
{
    @media only screen and (max-width: 300px)
    {
        .vx-card__body{
            padding:0px !important;
        }
    }
}
</style>

<script>
import VueApexCharts from 'vue-apexcharts'
import Member from '@/database/models/member'

export default {
    components:{
        VueApexCharts
	},
    props: {

    },
    data(){
        return{
            homme : 0,
            femme : 0,

            themeColors: [ Member.color.actif, Member.color.essai ],
            donutChart:
            {
                series: [0, 0 ],
                chartOptions:
                {
                    labels: ["Homme", "Femme" ],
                    colors: [ Member.color.actif, Member.color.essai ],
                    responsive: [
                    {
                        breakpoint: 480,
                        options:
                        {
                            legend:
                            {
                                show: false,
                                position: 'bottom'
                            },
                        }
                    }],
                    legend:{
                        show: false,
                    },
                },
            }
        }
    },
    mounted:function()
	{
        this.refresh()
    },
    methods:{
        refresh()
        {
            Member.getTabAllMemory((list)=>
            {
                //Build data
                for( var i=0; i< list.length; i++)
                {
                    var buffer = list[i];

					switch( buffer.gender )
					{
						case 'm':
							this.homme++;
						break;
						case 'f':
							this.femme++;
						break;
					}
                }

                //Update graph
                this.donutChart.series[0] = this.homme;
                this.donutChart.series[1] = this.femme;

                //refresh graph
                this.$refs.donut.refresh();

            });
        }
    }
}

</script>